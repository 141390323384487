<template>
  <div class="container-referral-dynamic">
    <div class="dynamic-fields">
      <input
        class="my-inputs"
        v-for="(field, idx) in question.referral.dynamicFields" :key="idx"
        :id="`field-${idx}`"
        :type="field.type"
        v-model="field.value"
        :placeholder="field.placeholder"
        @keyup="keyMonitor(idx, field.value)">
        <p v-if="alertEmail" class="alert-message">{{ alertMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'referralDynamic',
  props: ['question'],
  data () {
    return {
      itemsRequired: [],
      showRefList: false,
      showButton: false,
      refList: [],
      showLabel: false,
      ref: {},
      fields: [],
      referralEntries: {},
      macthEmail: '',
      alertEmail: false,
      alertMessage: this.$i18n.t('referral.alert_email')
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit()
      }
    })

    this.checkFields()
  },
  computed: {
    mountedSurvey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  methods: {
    checkFields () {
      const fields = Object.entries(this.question.referral.dynamicFields)

      fields.forEach(item => {
        if (item[1].required) {
          this.itemsRequired.push({ name: item[1].label, index: item[0], done: false })
          document.getElementById(`field-${item[0]}`).style.borderColor = '#ff00004d'
        }
        if (this.itemsRequired.length === 0) {
          setTimeout(() => {
            this.$root.$emit('show-btn', true)
          }, 100)
        } else {
          this.$root.$emit('show-btn', false)
        }
      })
    },
    submit () {
      const values = []
      this.question.referral.dynamicFields.forEach(o => {
        if (!o.value) { o.value = '' }
        values.push(o.value)
      })
      const toSubmit = Object.assign({}, this.referralEntries, values)
      this.nextQuestion({ referralEntries: toSubmit, rating: 100 })
    },
    nextQuestion (data) {
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
    },
    keyMonitor (idx, value) {
      if (this.itemsRequired.length > 0) {
        const input = document.getElementById(`field-${idx}`)

        if (input.type === 'email') {
          input.onpaste = e => e.preventDefault()
          value = input.value.trim()
          this.macthEmail = value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
          value.length > 0 && !this.macthEmail ? this.alertEmail = true : this.alertEmail = false
        }

        if (input.type === 'tel') {
          input.value = input.value.replace(/[^\d,]/g, '')
        }

        this.itemsRequired.forEach((item, i) => {
          if (parseInt(item.index) === idx) {
            if (input.value.length > 0) {
              input.style.borderColor = '#cacaca'
              item.done = true
            }

            if (input.value.length <= 0) {
              item.done = false
              input.style.borderColor = '#ff00004d'
            }
          }
        })

        const items = this.itemsRequired.map((e, i) => { return e })
        const found = items.find(e => !e.done)

        if (!found && !this.alertEmail) {
          this.$root.$emit('show-btn', true)
        } else {
          this.$root.$emit('show-btn', false)
        }
      } else {
        this.$root.$emit('show-btn', true)
      }
    }
  }
}
</script>
<style lang="scss">
.container-referral-dynamic {
  .dynamic-fields {
    min-height: 370px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .my-inputs {
      background: #ffffff47;
      width: 90%;
      height: 45px;
      outline: none;
      margin: 3px 0;
      padding-left: 5px;
      font-size: 16px;
      color: #000000;
      border: 1px solid #cacaca;
      border-radius: 3px;
    }
    .my-inputs::placeholder {
      color: #959595;
    }
    .alert-message {
      text-align: center;
      width: 100%;
      margin-top: 5px;
      color: #D91212ed;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .container-referral-dynamic {
    .dynamic-fields {
      min-height: var(--body-height) !important;
      width: 100%;
      .my-inputs {
        width: 95% !important;
      }
    }
  }
}
</style>
